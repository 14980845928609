.well {
  min-height: 100vh;
  align-items: center;
  padding: 6.5625rem 0;
  display: flex;
  position: relative;
}

.well__background {
  z-index: 0;
  width: 100%;
  height: 100%;
  background-image: url("devlabs-way-tablet.793afa33.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20% 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (max-width: 991.98px) {
  .well__background {
    background-image: url("devlabs-way-tablet.793afa33.jpg");
  }
}

@media (max-width: 767.98px) {
  .well__background {
    background-image: url("devlabs-way-mobile.ea0eb592.jpg");
  }
}

.well__overlay {
  z-index: 1;
  width: 100%;
  height: calc(100% + 2px);
  opacity: .7;
  background: #000;
  position: absolute;
  top: -1px;
  left: 0;
}

.well__content {
  z-index: 2;
  color: #fff;
  padding-bottom: 1.25rem;
  position: relative;
}

.well__title {
  margin-bottom: 1.125rem;
}

.well__entry {
  max-width: 32.1875rem;
  margin-bottom: 2rem;
  font-weight: 400;
}

@media (max-width: 991.98px) {
  .well {
    min-height: 622px;
    padding: 80px 0;
  }

  .well__content {
    padding-bottom: 0;
  }
}

/*# sourceMappingURL=index.75ec7ac4.css.map */
