@import '../variables';
@import '../mixins';

/* ------------------------------------------------------------ *\
	Well
\* ------------------------------------------------------------ */

.well {
	position: relative;
	display: flex;
	align-items: center;
	min-height: 100vh;
	padding: rem(105px) 0;

	&__background {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		overflow: hidden;
		width: 100%;
		height: 100%;
		padding: 20% 0;
		background-image: url('../../images/home/devlabs-way-tablet.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		@include media-breakpoint-down(md) {
			background-image: url('../../images/home/devlabs-way-tablet.jpg');
		}
		@include media-breakpoint-down(sm) {
			background-image: url('../../images/home/devlabs-way-mobile.jpg');
		}
	}

	&__overlay {
		position: absolute;
		/*Fix parallax 1px white bar showing */
		top: -1px;
		left: 0;
		z-index: 1;
		width: 100%;
		height: calc(100% + 2px);
		background: #000;
    opacity: 0.7;
	}

	&__content {
		position: relative;
		z-index: 2;
		padding-bottom: rem(20px);
		color: $white;
	}

	&__title {
		margin-bottom: rem(18px);
	}

	&__entry {
		max-width: rem(515px);
		margin-bottom: rem(32px);
		font-weight: 400;
	}
}

/* === Tablet === */
@include media-breakpoint-down(md) {
	.well {
		padding: 80px 0;
		min-height: 622px;

		&__content {
			padding-bottom: 0;
		}
	}
}